// header after loggin in

header.container {
  .logo {
    display: block;
    float: left;
    img {
      width: 140px;
    }
  }

  h1.persona {
    display: block;
    float: left;
    margin: 17px 0 0 25px;
    color: $gray;
    font-size: 20px;
    line-height: 1em;
    font-weight: 500 !important;
  }

  .network {
    text-align: right;
  }

  @media screen and (max-width: $breakpoint-phone) {
    h1.persona {
      margin-left: 0;
    }

    .network {
      text-align: left;
    }
  }
}
