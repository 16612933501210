.dashboard {
  .identity-information {
    .advancedToggle {
      border: none;
      background: none;
      cursor: pointer;
      color: $blue;
      font-size: 14px;
    }

    h2 {
      font-size: 14px;
      font-weight: 500 !important;
      color: $gray;
      text-transform: uppercase;
    }

    p.value {
      color: $black;
      font-size: 18px;
    }
    ul.value {
      margin: 10px 0;
      padding: 0;
      list-style-type: none;
      li {
        font-size: 18px;
        color: $black;
      }
    }
  }

  .identity-balance {
    button.circle-plus {
      margin-top: -10px;
      margin-right: -10px;
    }

    .token {
      padding-bottom: 20px;

      .heading-symbol {
        color: $gray;
        font-size: 14px;
        text-transform: uppercase;
      }
      .balance {
        color: $turquoise;
        font-size: 50px;

        .hover-content {
          font-size: 16px;
        }
      }
      .symbol {
        color: $turquoise;
        font-size: 30px;
        margin-left: 15px;
      }
      .conversion {
        margin-left: 25px;
        color: $gray;
        font-size: 20px;
      }
    }
  }
}
