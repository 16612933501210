.app {
  flex-grow: 1;
  overflow: auto;

  * {
    font-family: 'Roboto', sans-serif;
  }

  footer.app-footer {
    margin: 75px 0 25px 0;
    color: $gray;
    text-align: center;

    img {
      max-width: 160px;
      margin-bottom: 15px;
    }

    p {
      font-size: 12px;
      margin: 2px;
      padding: 0;
      font-family: 'Rubik', sans-serif;
    }
  }
}
